<template>
  <div class="down">
    <div class="nav-title">
      <i></i>首页— 下载中心
    </div>
    <div class="content">
      <div class="title">
        <span class="active">下载中心</span>
      </div>
      <div class="list">
        <div class="top">
          <div class="left">
            <div class="sort" @click="handleSort('creationTime')">
              <span>下载时间</span>
              <img src="../../assets/paixu1.png" v-if="creationTime" alt="" />
              <img src="../../assets/paixu2.png" v-if="!creationTime" alt="" />
            </div>
            <div class="input">
              <input type="text" placeholder="输入图书标题" v-model="inputVal" @keyup.enter="onSearch">
              <img src="../../assets/fangdajing.png" alt=""  @click="onSearch">
            </div>
            <div class="select-box">
              <a-select
                :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                v-model="progressSimple"
                style="width: 190px"
                placeholder="筛选繁简版本"
                :options="progressSimpleList"
                @change="onSearch"
              >
              </a-select>
            </div>
          </div>
          <div class="right">
            <div class="button-search" @click="handleDown">下载</div>
            <span class="button-primary" @click="handleAll">全选</span>
            <span class="button-primary" @click="handleCancleAll">取消全选</span>
          </div>
        </div>

        <a-table
          :columns="columns"
          :data-source="downloadData.rows"
          :row-key="record => record.id"
          :rowSelection="{
            type: 'checkbox',
            onChange: handleSelect,
            selectedRowKeys: checkedNewsList
          }"
          :pagination="{
            total: downloadData.total,
            current: pageNum,
            pageSize: pageSize,
            showTotal: () => `共${downloadData.total}条`,
            showSizeChanger: true
          }"
          @change="handlePageChange"
        >
          <template slot="title">
            <div class="download-top" style="display: flex;">
              <span>下载列表</span>
              <span @click="handleDelClose('all')">
                <img src="@/assets/icon-delete.png" class="icon-read"/>全部删除
              </span>
            </div>
          </template>
          <template slot="step" slot-scope="text, record">
            <div class="stepBox">
              <img
                v-for="item in scheduleArr" 
                :src="
                record[item.title] === 1 ? item.checked 
                : record[item.title] === 0 ? item.unChecked
                : record[item.title] === 2 ? item.error : ''"
              >
            </div>
          </template>
          <template slot="fileFormat" slot-scope="text">
            {{ text == 0 ? 'XML' : text == 1 ? 'DOC' : ''}}
          </template>
          <template slot="action" slot-scope="text, record">
            <div class="actionBtn">
              <span 
                @click="handleDownOne(record.id)"
              >
                <img src="@/assets/icon-down-one.png" />
                下载
              </span>
              <span 
                @click="handleDelClose('one', record)"
              >
                <img src="@/assets/icon-delete.png" />
                删除
              </span>
            </div>
          </template>
        </a-table>
      </div>
    </div>

    <HintOrDownModal
      :visible="deleteVisible"
      tipsType="tipsOne"
      :handleClose="handleDelClose"
      :handleCallback="handleDelCallBack"
    />
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import { getDownloadList, downloadDelete } from '@/api/download'
import HintOrDownModal from '@/components/HintOrDownModal.vue'
import unCheckedPic from '@/assets/picture.png';
import unCheckedOcr from '@/assets/OCR-g.png';
import unCheckedText from '@/assets/wenben.png';
import unCheckedA from '@/assets/daxieA.png';
import unCheckedTransform from '@/assets/fanjian.png'

import checkedPic from '@/assets/checkedPic.png';
import checkedOcr from '@/assets/checkedOcr.png';
import checkedText from '@/assets/checkedText.png';
import checkedA from '@/assets/checkedA.png';
import checkedTransform from '@/assets/checkedFanjian.png'

import errorPic from '@/assets/picture-red.png';
import errorOcr from '@/assets/ocr-red.png';
import errorText from '@/assets/text-red.png';
import errorA from '@/assets/a-red.png';
import errorTransform from '@/assets/fanjian-red.png'
import { downloadByUrl } from '@/utils/download'

export default {
  components: { HintOrDownModal },
  data() {
    return {
      creationTime: false,
      scheduleArr: [
        {
          title: 'bookProgressImg',
          checked: checkedPic,
          unChecked: unCheckedPic,
          error: errorPic,
          text: '图文校对'
        },
        {
          title: 'bookProgressOcr',
          checked: checkedOcr,
          unChecked: unCheckedOcr,
          error: errorOcr,
          text: 'ocr识别'
        },
        {
          title: 'bookProgressBd',
          checked: checkedText,
          unChecked: unCheckedText,
          error: errorText,
          text: '标点'
        },
        {
          title: 'bookProgressBx',
          checked: checkedA,
          unChecked: unCheckedA,
          error: errorA,
          text: '标点标线'
        },
        {
          title: 'bookProgressSimple',
          checked: checkedTransform,
          unChecked: unCheckedTransform,
          error: errorTransform,
          text: '繁转简'
        },
      ],
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
        },
        {
          title: '图书名称',
          dataIndex: 'bookName',
        },
        {
          title: '页数',
          dataIndex: 'countPage',
        },
        {
          title: '识别字数',
          dataIndex: 'countWord',
        },
        {
          title: '进度',
          dataIndex: 'step',
          scopedSlots: { customRender: 'step' },
        },
        {
          title: '下载格式',
          dataIndex: 'fileFormat',
          scopedSlots: { customRender: 'fileFormat' },
        },
        {
          title: '下载次数',
          dataIndex: 'downloadCount',
        },
        {
          title: '时间',
          dataIndex: 'sysDownloadTime',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      downloadData:{
        rows: [],
        total: 0
      },
      inputVal: '',
      pageNum: 1,
      pageSize: 10,
      checkedNewsList: [], // 选中的列表
      deleteVisible: false,
      deleteType: '', // 删除的类型（单个或批量）
      deleteId: '',
      progressSimple: undefined,
      progressSimpleList: [
        {
          value: 2,
          label: '全部'
        },
        {
          value: 0,
          label: '繁体版'
        },
        {
          value: 1,
          label: '简体版'
        },
      ]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取列表
    async getList() {
      this.checkedNewsList = []
      const res = await getDownloadList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        bookName: this.inputVal,
        sort: this.creationTime ? 'asc' : 'desc',
        progressSimple: this.progressSimple
      })

      res.rows.map((item, index)=> {
        item.key = index + 1
      })

      this.downloadData = res
    },
    // 翻页
    handlePageChange(e) {
      this.pageNum = e.current
      this.pageSize = e.pageSize

      this.getList()
    },
    // 表格选中
    handleSelect(e) {
      this.checkedNewsList = e
    },
    // 搜索
    onSearch() {
      this.pageNum = 1
      this.getList()
    },
    handleDelClose(type, data) {
      if (type == 'all') {
        // if (!this.checkedNewsList.length) {
        //   return message.warning('请先选择')
        // }
      } else if (data) {
        this.deleteId = data.id
      }
      this.deleteType = type
      this.deleteVisible = !this.deleteVisible
    },
    // 确定删除
    handleDelCallBack() {
      if (this.deleteType == 'all') {
        this.onAllDelete()
      } else {
        this.onDelete()
      }
    },
    // 删除消息
    async onDelete() {
      try {
        await downloadDelete({
          id: this.deleteId
        })

        this.deleteVisible = false
        message.success('删除成功！');
        this.getList()
      } catch (error) {
        console.log('error', error)
      }
    },
    // 批量删除
    async onAllDelete() {
      try {
        await downloadDelete({
          id: this.checkedNewsList.length ? this.checkedNewsList.join(',') : 0
        })
        this.deleteVisible = false
        message.success('删除成功！');
        this.getList()
      } catch (error) {
        console.log('error', error)
      }
    },
    // 排序
    handleSort(type) {
      this[type] = !this[type]

      this.getList()
    },
    // 全选
    handleAll() {
      this.checkedNewsList = this.downloadData.rows.map(item => {
        return item.id
      })
    },
    // 取消全选
    handleCancleAll() {
      this.checkedNewsList = []
    },
    // 批量下载
    handleDown() {
      if (!this.checkedNewsList.length) {
        return message.warning('请先选择')
      }
      downloadByUrl({
        requestUrl: `download/befDownload/${this.checkedNewsList.join(',')}`,
        url: `download/downloadFile/${this.checkedNewsList.join(',')}`,
        target: '_self',
      })

      setTimeout(() => {
        this.getList()
      }, 500)
    },
    // 单个下载
    handleDownOne(id) {
      downloadByUrl({
        requestUrl: `download/befDownload/${id}`,
        url: `download/downloadFile/${id}`,
        target: '_self',
      })

      setTimeout(() => {
        this.getList()
      }, 500)
    }
  }
};
</script>

<style lang="less">
.down {
  width: 100%;
  min-height: 787px;
  background: #edf0f2;
  padding: 46px 0px 100px;
  box-sizing: border-box;

  .content {
    width: auto;
    padding: 0px 51px;

    .title {
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
      }
      .button-primary {
        height: 34px !important;
        margin-right: 0;
        color: #6868CE;
      }
    }

    .list {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      margin-top: 10px;
      padding: 0px 26px 16px 26px;
      box-sizing: border-box;

      .top {
        width: 100%;
        height: 59px;
        border-bottom: 1px dashed #c6c4c4;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          align-items: center;
          .input {
            width: 190px;
            height: 32px;
            border: 1px solid #979797;
            position: relative;
            margin-left: 50px;

            input {
              width: 100%;
              height: 100%;
              border: none;
              outline: none;
              padding: 3px 5px;

              :focus {
                outline: none;
              }
            }


            img {
              width: 19px;
              height: 19px;
              position: absolute;
              right: 13px;
              top: 6px;
              cursor: pointer;
            }
          }

          .sort {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 28px;

            span {
              font-size: 14px;
              font-weight: 400;
              color: #363636;
              margin-right: 15px;
            }

            img {
              width: 8px;
              height: 100%;
              cursor: pointer;
            }
          }
        }

        .right {
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .stepBox {
      img {
        width: 19px;
        height: 19px;
        margin-right: 8px;
      }
    }
  }

  .icon-read {
    width: 18px;
    margin-right: 6px;
    vertical-align: middle;
  }

  .download-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span {
      display: flex;
      align-items: center;
      &:nth-of-type(2) {
        font-size: 14px !important;
      }
    }
  }

  .actionBtn {
    display: flex;
    span {
      cursor: pointer;
    }
    span:nth-of-type(1) {
      margin-right: 20px;
    }
  }

  .select-box {
    margin-left: 10px;
  }
}
</style>
